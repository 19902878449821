import React, { Component, useState } from 'react';
import { Create, SimpleForm, TextInput, NumberInput, SelectInput } from 'react-admin';
import { Grid } from '@material-ui/core';

export default class ConfigurationCreate extends Component {

	render() {
		return (
			<Create {...this.props} title="Crear banner">
				<SimpleForm>
					<Grid fullWidth spacing={16}>
						<Grid item xl={12} spacing={6}>
							<TextInput source="url" label="Url" fullWidth />
						</Grid>
						<Grid item xl={12} spacing={6}>
						<SelectInput
							source="type"
							label="Tipo"
							choices={[
								{ id: 'HOMETOP', name: 'Home' },
								{ id: 'HOMEPROMO', name: 'Home Promo' },
                { id: 'POPUP', name: 'Home Popup' }
							]}
							fullWidth
						/>
						</Grid>
						<Grid item xl={12} spacing={6}>
							<NumberInput source="priority" label="Prioridad" fullWidth />
						</Grid>
					</Grid>
				</SimpleForm>
			</Create>
		);
	}
}
